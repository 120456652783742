/* CSS */

/* Default border color */
input {
    border: 1px solid #dadada; /* Black border color */
    outline: none; /* Remove the default focus outline */
  }
  
  /* Focus state - Custom focus ring color */
  input:focus {
    border-color: #afafaf; /* You can keep the border color the same or modify as needed */
  }
  