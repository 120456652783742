/* Style for the container with custom scrollbar */
.scrollable {
    /* Define the dimensions and overflow behavior */
   
    overflow-y: scroll; /* Enable vertical scrollbar */
    position: relative; /* Ensure positioning context for pseudo-elements */
  
    /* Style for the scrollbar track */
    &::-webkit-scrollbar {
      width: 4px; /* Set the width of the scrollbar */
    }
  
    &::-webkit-scrollbar-track {
        background-color: rgb(255, 255, 255); /* Set the color of the thumb */
    }
  
    /* Style for the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
     
      background-color: rgb(148, 148, 148); /* Set the color of the track */
      border-radius: 5px; /* Adjust the border-radius as needed */
    }
  }
  
  /* Additional styling for the content inside the scrollable container */
  .content {
    /* Set padding to ensure content isn't hidden by the scrollbar */
    padding-right: 2px; /* Adjust according to the scrollbar width */
  }
  