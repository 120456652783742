

body {
    overflow:auto;
}

.max_calc{
    max-height: calc(100vh - 50px)
}

.custom-scrollbar {
    overflow: auto; /* Enable scrolling for the element */
}

.custom-scrollbar::-webkit-scrollbar {
    width: 2px;
    color: #606060;
    background-color: #606060;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #bebbbb; /* Customize the scrollbar track color */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background-color:  rgb(53, 52, 52); /* Customize the scrollbar thumb color */
}

textarea {
    border: none;
    border-color: transparent;
  }
  
  /* Remove border when focused */
  textarea:focus {
    outline: none;
  }
