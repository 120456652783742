
@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(20px) scale(0.8);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes stretch {
  from {
    height: 20%;
  }
  to {
    height: 100%;
  }
}

.animate-stretch {
  animation: stretch ease-out 0.6s forwards;
}

.cross {
  position: absolute;
  width: 40px;
  height: 2px;
  background-color: black;
  transition: transform ease-in-out 0.3s;
}

.crossed1 {
  transform: rotate(45deg);
}

.crossed2 {
  transform: rotate(-45deg);
} 
