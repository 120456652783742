.task {
  background-color: transparent;
  border-radius: 5px;
  color: black;
  width: 100%;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  touch-action: none;
}
.task:hover{
  background-color: #F4F4F4;


}


.checkbox {
  height: 20px;
  width: 20px;
}
