.dropdown-container {
    position: relative;
  }
  
  .dropdown-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 0.375rem; /* rounded-lg */
    padding: 1rem;
    cursor: pointer;
    font-family: 'Manrope', sans-serif;
    font-size: 0.875rem; /* text-sm */
    font-weight: 500; /* font-medium */
    line-height: 1.25rem; /* leading-5 */
    letter-spacing: 0.015em; /* tracking-tight */
  }
  
  .dropdown-icon {
    color: #000000;
  }
  
  .dropdown-menu {
    background-color: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 0.375rem; /* rounded-lg */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* shadow-lg */
    z-index: 10;
  }
  
  .dropdown-item {
    padding: 1rem;
    font-family: 'Manrope', sans-serif;
    font-size: 0.875rem; /* text-sm */
    font-weight: 500; /* font-medium */
    line-height: 1.25rem; /* leading-5 */
    letter-spacing: 0.015em; /* tracking-tight */
  }
  
  .dropdown-item:hover {
    background-color: #f5f5f5; /* yellow-100 */
  }
  