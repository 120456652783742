@font-face {
    font-family: 'NewSpirit';
    src: url('new_spirit_regular.otf') format('opentype');
    /* You may need to adjust the font file format ('opentype') if necessary */
  }

.font_inter{
    font-family: 'Inter', sans-serif;
}

.font_new_spirit{
    font-family: "Manrope", sans-serif;
}


.font_cooper{
    font-family: 'CooperMdBt';
    src: url('coopermdbtmedium.otf') format('opentype');


}


.font_manrope{
    font-family: "Manrope", sans-serif;
}




.manrope-200 {
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}
.manrope-400 {
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  
  .manrope-500 {
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }
  .manrope-800 {
    font-family: "Manrope", sans-serif;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
  }
