/* Spinner.css */
.spinner {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 4px solid rgb(0, 0, 0); /* Red border */
    border-top: 4px solid #ccc; /* Grey border */
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  