@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Import Tailwind CSS colors as variables */
:root {
  --border-color: #f1f1f1;
}

.custom-progress-bar .p-progressbar .p-progressbar-value {
  background-color: #ffffff;
}

* {
  font-family: "Manrope", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
}

.central {
  display: flex;
  align-items: center;
  justify-content: center;
}
