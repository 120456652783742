/* Hide default scrollbar for all browsers */
.custom-scrollbar {
  overflow: auto;
  scrollbar-width: 2px; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}

/* Custom scrollbar styles for Webkit-based browsers */
.custom-scrollbar::-webkit-scrollbar {
  width: 2px; /* Customize width */
  height: 8px; /* Customize height */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #525252; /* Customize thumb color */
  border-radius: 4px; /* Customize thumb radius */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #1d1d1d; /* Customize track color */
  border-radius: 4px; /* Customize track radius */
}


  .textarea {
    resize: none;
    height: 100px;
    width: 100%;
    border: 1px solid #ccc;
  }
  